import WoundHealingTemplate from '@/modules/questionnaire/components/steps/common/medical-background/wound-healing/WoundHealingTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/WoundHealing/WoundHealingTemplate',
  component: WoundHealingTemplate
};

const createStory = props => () => ({
  components: { WoundHealingTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><wound-healing-template v-bind="$options.storyProps"/></div>'
});

export const YesAnswer = createStory({
  prolongedHealingTime: BOOLEAN.YES
});

export const NoAnswer = createStory({
  prolongedHealingTime: BOOLEAN.NO
});
